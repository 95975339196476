function myRugColors(numCycles) {
    let colors = [];
    // const increment = parseInt(numCycles / 3);
    // const middle = parseInt(increment / 2) - 1;
    // console.log("increment", increment, "numCycles", numCycles, "middle", middle);

    // my rug :)
    const white = "#eee8da";
    const wheat = "#d4c3b1";
    const turquoise = "#5997a5";
    const lightBlue = "#8daac2";
    const raspberry = "#7b4f6e"; // rgb(89, 81, 84)
    const darkTeal = "#198684"; // "#276d6b"
    const darkGray = "#414359";
    const navy = "#161B4F";
    for (let i = 0; i < 30; i += 10) {
        const guestColor = i === 0 ? white : i === 10 ? wheat : raspberry;
        const secondaryGuest = i === 0 ? wheat : raspberry;
        const tertiaryGuest = i === 0 ? lightBlue : i === 10 ? darkTeal : raspberry;

        if (i === 20) {
            colors.push([lightBlue, guestColor, guestColor]);
        } else {
            colors.push([lightBlue, guestColor, guestColor]);
        }
        colors.push([lightBlue, guestColor, guestColor]);
        colors.push([lightBlue, guestColor, guestColor]);
        colors.push([lightBlue, guestColor, guestColor]);
        if (i === 0) {
            // halfway through, do a turquoise but swap here, bc did an extra row by accident
            colors.push([lightBlue, guestColor, guestColor]);
            colors.push([lightBlue, turquoise, guestColor]);
        } else {
            colors.push([lightBlue, turquoise, guestColor]);
            colors.push([lightBlue, i === 20 ? lightBlue : tertiaryGuest, guestColor]);
        }
        if (i === 20) {
            // only the last (third) time through
            colors.push([lightBlue, lightBlue, guestColor]);
            colors.push([lightBlue, darkGray, guestColor]);
            colors.push([lightBlue, navy, darkGray]);
            colors.push([navy, navy, darkGray]);
        } else {
            // the first and second times through
            colors.push([lightBlue, tertiaryGuest, guestColor]);
            colors.push([lightBlue, tertiaryGuest, guestColor]);
            colors.push([lightBlue, tertiaryGuest, guestColor]);
            colors.push([lightBlue, tertiaryGuest, secondaryGuest]);
        }
    }
    return colors;
}

export { myRugColors };

// const fabricValues = {
//     Cornsilk: '#eee8da',
//     SteelBlue: '#6481a2', // darker blue than mainColor
//     Wheat: '#d4c3b1',
//     DarkSalmon: '#a48185',
//     CadetBlue: '#5997a5',
//     SaddleBrown: '#5c3e27'
// }
// const mainColor = '#8daac2'
// for (let i = 0; i < numCycles; i += increment) {
//     const guestColor = i === 0 ? fabricValues.Cornsilk : i === increment ? fabricValues.Wheat : fabricValues.DarkSalmon
//     const secondaryGuest = i === 0 ? fabricValues.Wheat : fabricValues.DarkSalmon

//     for (let j = 0; j < increment; j++) {
//         if (j === middle) {
//             // do a cadet blue
//             colors.push([mainColor, fabricValues.CadetBlue, guestColor])
//         } else if (i === numCycles - increment && j >= increment - 2) {
//             colors.push([fabricValues.SteelBlue, fabricValues.SaddleBrown, fabricValues.SteelBlue])
//             colors.push([fabricValues.SteelBlue, fabricValues.SaddleBrown, fabricValues.SteelBlue])
//             j += 1
//         } else if (j >= increment - 2) {
//             colors.push([mainColor, mainColor, guestColor])
//             colors.push([mainColor, mainColor, secondaryGuest])
//             j += 1
//         } else if (j < middle) {
//             colors.push([mainColor, guestColor, guestColor])
//         } else {
//             colors.push([mainColor, mainColor, guestColor])
//         }
//     }
// }
// // well, just cut them off, then.
// if (colors.length > numCycles) {
//     colors.splice(numCycles, colors.length - numCycles)
// }
// my rug doesn't need to be a preset, it's gonna be enough just to show the screenshot.
