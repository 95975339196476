function checkLengths(cols) {
    return cols[1] && cols[1].length === 7 && cols[2] && cols[2].length === 7 && cols[3] && cols[3].length === 7
}

function processUpload(csv, callback) {
    const userColors = []
    if (typeof FileReader !== 'undefined') {
        const reader = new FileReader()
        reader.onload = function (e) {
            const rows = e.target.result.split('\n')
            rows.forEach((r) => {
                const cols = r.replace(/["]+/g, '').split(',')
                if (cols[0] !== 'Row' && checkLengths(cols)) {
                    userColors.push([cols[1], cols[2], cols[3]])
                }
            })
            if (userColors.length > 0) {
                callback(userColors)
            } else {
                alert('We were unable to load the pattern.')
            }
        }
        reader.readAsText(csv)
    } else {
        alert('This browser does not support HTML5.')
    }

    // var fileUpload = document.getElementById("upload");
    // var regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.csv|.txt)$/;
    // this works, but i don't really care what they call it? so.
    // if (regex.test(fileUpload.value.toLowerCase())) {
    // } else {
    //     alert("Please upload a valid CSV file.");
    // }
}

export { processUpload }
