function staggeredRingsColors(numCycles) {
    let colors = [];
    const increment = parseInt(numCycles / 3);

    const white = "#ffffff";
    const lightGray = "#d3d3d3";
    const lightBlue = "#dde0ec";
    const medBlue = "#949fc7";
    const darkBlue = "#394b86";
    const mahogany = "#42002a";

    for (let i = 0; i < numCycles; i += increment) {
        for (let j = 0; j < increment; j++) {
            if (i === 0) {
                if (j < increment / 3) {
                    colors.push([lightBlue, lightGray, white]);
                } else if (j < (2 * increment) / 3) {
                    colors.push([medBlue, lightBlue, lightGray]);
                } else {
                    colors.push([medBlue, medBlue, lightBlue]);
                }
            } else if (i === increment) {
                if (j < increment / 3) {
                    colors.push([medBlue, lightGray, lightBlue]);
                } else if (j < (2 * increment) / 3) {
                    colors.push([darkBlue, medBlue, lightGray]);
                } else {
                    colors.push([darkBlue, darkBlue, medBlue]);
                }
            } else {
                if (j < increment / 3) {
                    colors.push([darkBlue, lightGray, medBlue]);
                } else if (j < (2 * increment) / 3) {
                    colors.push([mahogany, darkBlue, lightGray]);
                } else {
                    colors.push([mahogany, mahogany, darkBlue]);
                }
            }
        }
    }
    return colors;
}

export { staggeredRingsColors };
