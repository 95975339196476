function threeRingsColors(numCycles) {
    let colors = [];
    const increment = parseInt(numCycles / 3);

    // change to this:
    const ecru = "#e7e0c4";
    const camel = "#c09b75";
    const brown = "#905a21";

    const lightGray = "#d3d3d3";
    // const light = "#ece5dd";
    // const grayBrown = "#9b8c7c";
    // const brown = "#a07a07";
    // const sand = "#d4c3b1";
    // const blue = "#49538D";
    // const darkBrown = "#6b5000";
    // const rust = "#b25e05";

    for (let i = 0; i < numCycles; i += increment) {
        for (let j = 0; j < increment; j++) {
            if (i === 0) {
                colors.push([lightGray, ecru, ecru]);
                // colors.push([light, lightGray, grayBrown]);
            } else if (i === increment) {
                colors.push([lightGray, camel, camel]);
                // colors.push([brown, lightGray, sand]);
            } else {
                colors.push([lightGray, brown, brown]);
                // colors.push([darkBrown, lightGray, rust]);
            }
        }
    }
    return colors;
}

export { threeRingsColors };
