function rainbowColors(numCycles) {
    // rainbow
    let colors = [];
    const increment = parseInt(numCycles / 6);
    for (let i = 0; i < numCycles; i++) {
        if (i < increment * 1) {
            colors.push(["#dc143c", "#d3d3d3", "#ff8c00"]);
        } else if (i < increment * 2) {
            colors.push(["#ff8c00", "#d3d3d3", "#ffd700"]);
        } else if (i < increment * 3) {
            colors.push(["#ffd700", "#d3d3d3", "#2e8b57"]);
        } else if (i < increment * 4) {
            colors.push(["#2e8b57", "#d3d3d3", "#4682b4"]);
        } else if (i < increment * 5) {
            colors.push(["#4682b4", "#d3d3d3", "#800080"]);
        } else {
            colors.push(["#800080", "#d3d3d3", "#000080"]);
        }
    }
    return colors;
}

export { rainbowColors };

// // Nice Rainbow
// for (let i = 0; i < 30; i++) {
//     if (i < 5) {
// red, lightgray, darkorange
//         colors.push(["#dc143c", "#d3d3d3", "#ff8c00"])
//     }
//     else if (i < 10) {
// darkorange, lightgray, gold
//         colors.push(["#ff8c00", "#d3d3d3", "#ffd700"])

//     }
//     else if (i < 15) {
// gold, lightgray, seagreen
//         colors.push(["#ffd700", "#d3d3d3", "#2e8b57"])
//     }
//     else if (i < 20) {
// seagreen, lightgray, steelblue
//         colors.push(["#2e8b57", "#d3d3d3", "#4682b4"])

//     }
//     else if (i < 25) {
// steelblue, lightgray, purple
//         colors.push(["#4682b4", "#d3d3d3", "#800080"])

//     }
//     else {
// purple, lightgray, navy
//         colors.push(["#800080", "#d3d3d3", "#000080"])
//     }
// }

// Crimson "#dc143c"
// LightGray "#d3d3d3"
// DarkOrange "#ff8c00"
// Gold "#ffd700"
// SeaGreen "#2e8b57"
// steelblue "#4682b4"
// purple "#800080"
// navy "#000080"
