function kiwiColors(numCycles) {
    // kiwi
    let colors = [];
    const increment = parseInt(numCycles / 3);
    for (let i = 0; i < numCycles; i++) {
        if (i < increment) {
            colors.push(["#BBEF80", "#BBEF80", "#BBEF80"]);
        } else if (i < increment * 2 - 1) {
            colors.push(["#88d086", "#a4c6a3", "#000000"]);
        } else if (i < increment * 3 - 2) {
            colors.push(["#6da86c", "#a4c6a3", "#6da86c"]);
        } else if (i < increment * 3 - 1) {
            colors.push(["#426641", "#a4c6a3", "#426641"]);
        } else {
            colors.push(["#887e23", "#a4c6a3", "#887e23"]);
        }
    }
    return colors;
}

export { kiwiColors };

// kiwi
// light gray green "#a4c6a3"
// light green "#88d086"
// darker green "#6da86c"
// dark green "#426641"
// light brown "#887e23"
// for (let i = 0; i < 30; i++) {
//     if (i < 5) {
//         colors.push(["#88d086", "#a4c6a3", "#88d086"])
//     }
//     else if (i < 10) {
//         colors.push(["#88d086", "#a4c6a3", "Black"])

//     }
//     else if (i < 28) {
//         colors.push(["#6da86c", "#a4c6a3", "#6da86c"])
//     }
//     else if (i < 29) {
//         colors.push(["#426641", "#a4c6a3", "#426641"])

//     }
//     else  {
//         colors.push(["#887e23", "#a4c6a3", "#887e23"])

//     }
// }
