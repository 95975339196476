function createDownload(colors) {
    let csv = 'Row,Color1,Color2,Color3\n'
    colors.forEach((row, i) => {
        row.unshift(i + 1)
        csv += row.join(',')
        csv += '\n'
    })

    // console.log(csv);
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'braid_planner_colors.csv'
    hiddenElement.click()
}

export { createDownload }
