import { Braider } from './braider.js'
const b = new Braider()
b.init()

const backLinks = document.querySelectorAll('.back-link')
Array.from(backLinks).forEach((link) => {
    link.addEventListener('click', function (event) {
        scrollTo(0, 0)
    })
})
