import Selection from '@simonwep/selection-js'
import '@simonwep/pickr/dist/themes/classic.min.css' // 'classic' theme
// import '@simonwep/pickr/dist/themes/monolith.min.css';  // 'monolith' theme
// import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme
import Pickr from '@simonwep/pickr'

import { createDownload } from './download'
import { processUpload } from './upload'

import { kiwiColors } from '../data/kiwiColors'
import { myRugColors } from '../data/myRugColors'
import { rainbowColors } from '../data/rainbowColors'
import { randomColors } from '../data/randomColors'
import { threeRingsColors } from '../data/threeRingsColors'
import { staggeredRingsColors } from '../data/staggeredRingsColors'
const _ = require('underscore')
const d3 = require('./lib/d3.v4.min.js')
// https://github.com/parcel-bundler/parcel/issues/333

const Braider = function (myRug) {
    const topMargin = 40
    const leftMargin = 20
    const inchesPerPixel = 10
    let svg, rugW, rugL, braidW, numCycles, inchesRatio

    let colorCounts = {}
    let colors = []

    // important!
    const linearSamplingRatio = 0.0626887266437166
    // if only I preserved my path to this "important!" number.
    // it's roughly 2600/41464, where 41464 is the number of total points
    // in a 5x7 rug path when drawn with arcs, like below.
    // And 2600 may have been adapted from Mike Bostock's path sampling
    // explanation: https://observablehq.com/@mbostock/fourier-series-path-sampling

    // set up output svg
    d3.selectAll('#output svg').remove()
    const outputSvg = d3
        .select('#output')
        .append('svg')
        .attr('width', 320)
        .attr('height', 440)
        .append('g')
        .attr('transform', 'translate(' + leftMargin + ',' + topMargin + ')')

    function init() {
        const submitSize = document.getElementById('submit-size')
        submitSize.addEventListener('click', sizeSubmitted, false)
        sizeSubmitted()
        // add all the listeners
        document.getElementById('clear-selection').addEventListener('click', clearSelection)
        document.getElementById('select-preset').addEventListener('change', loadPreset)
        document.getElementById('download').addEventListener('click', downloadPattern)
        document.getElementById('upload').addEventListener('input', uploadPattern)
        document.getElementById('home').addEventListener('click', showPlanner)
        document.getElementById('example-rug').addEventListener('click', showExample)
        document.getElementById('faq').addEventListener('click', showFAQ)
        document.getElementById('feedback').addEventListener('click', showFeedback)
        document.getElementById('how-to').addEventListener('click', showHowTo)
    }

    function loadPreset(event) {
        const preset = document.getElementById('select-preset').value
        switch (preset) {
            case 'rainbow':
                colors = rainbowColors(numCycles)
                break
            case 'three-rings':
                colors = threeRingsColors(numCycles)
                break
            case 'staggered-rings':
                colors = staggeredRingsColors(numCycles)
                break
            case 'kiwi':
                colors = kiwiColors(numCycles)
                break
            case 'random':
                colors = randomColors(numCycles)
                break
        }
        handleColorsUpdate()
    }

    function handleColorsUpdate() {
        checkColorsSize()
        // add color rows
        const parent = document.getElementById('rows-container')
        // clear any existing rows
        parent.textContent = ''
        colors.forEach((c, i) => {
            addColorRow(parent, c, i)
        })
        d3.selectAll('.braidlet').attr('stroke', (d, i) => {
            return colors[d][i % 3]
        })
        updateOutput()
        hideLoading()
    }

    function checkColorsSize() {
        if (colors.length > numCycles) {
            const extra = colors.length - numCycles
            colors.splice(numCycles, extra)
        }
        if (colors.length < numCycles) {
            // fill in "missing" colors by repeating last row
            const missing = numCycles - colors.length
            for (let i = 0; i < missing; i++) {
                // arg, avoid copy by reference:
                const copyColor = JSON.parse(JSON.stringify(colors[colors.length - 1]))
                colors.push(copyColor)
            }
        }
    }

    function sizeSubmitted() {
        // 5' x 7' default set in index.html, and min+max are 1 and 8 in index.html
        let rugWidth = parseInt(document.getElementById('rug-width').value)
        let rugLength = parseInt(document.getElementById('rug-length').value)
        // constrain dimensions
        if (rugWidth < 1) {
            rugWidth = 1
            document.getElementById('rug-width').value = 1
        }
        if (rugWidth > 8) {
            rugWidth = 8
            document.getElementById('rug-width').value = 8
        }
        if (rugLength < 1) {
            rugLength = 1
            document.getElementById('rug-length').value = 1
        }
        if (rugLength > 8) {
            rugLength = 8
            document.getElementById('rug-length').value = 8
        }

        showLoading().then(() => {
            // convert feet to inches; make width < length
            rugW = rugWidth < rugLength ? rugWidth * 12 : rugLength * 12
            rugL = rugLength > rugWidth ? rugLength * 12 : rugWidth * 12
            braidW = 1 // 1"
            // convert inches to pixels
            rugW *= inchesPerPixel
            rugL *= inchesPerPixel
            braidW *= inchesPerPixel
            numCycles = Math.round(rugW / 2 / braidW)
            inchesRatio = rugL - rugW

            resetSVG()
            if (myRug) {
                setupMyRug()
            } else if (colors.length === 0) {
                loadPreset()
            } else {
                handleColorsUpdate()
            }
            calculateCoordinates()
        })
    }

    function resetSVG() {
        // set up rug svg
        d3.selectAll('#svg-container svg').remove()
        svg = d3
            .select('#svg-container')
            .append('svg')
            .attr('width', rugW + leftMargin * 2)
            .attr('height', rugL + topMargin * 3)
            .append('g')
    }

    function calculateCoordinates() {
        // using the coords method, no inches need to be added
        // My actual rug: 25" first segment
        const firstSegment = inchesRatio + 0 * inchesPerPixel

        const translateX = parseInt(rugW / 2) + leftMargin
        // okay. the radius of the largest arc = 1/2 the rug width. you can prove it to yourself again if you need to, but that's it.
        // and the origin is not the center of the rug, it's the top of the bottom arc. which is why you need the radius.
        const translateY = parseInt(rugW / 2) + firstSegment + topMargin

        svg.selectAll('.linesGroup').remove()
        const linesGroup = svg
            .append('g')
            .attr('class', 'linesGroup')
            .attr('transform', 'translate(' + translateX + ', ' + translateY + ')')

        const coordSpan = inchesPerPixel
        const numStraightSegments = Math.ceil(firstSegment / coordSpan)
        const upperStartX = 0.5 * braidW
        const upperStartY = -firstSegment

        const storedCoords = []
        for (let cycle = 0; cycle < numCycles; cycle++) {
            // straight up
            for (let i = 0; i < numStraightSegments; i++) {
                if (cycle > 0 || i > 0) {
                    storedCoords.push([-cycle * braidW, -i * coordSpan])
                }
            }
            // upper arc
            const numArcSegments = cycle * 2 + 3
            let r = (0.5 + cycle) * braidW
            const dt = Math.PI / numArcSegments
            let t = Math.PI
            for (let i = 0; i < numArcSegments; i++) {
                const x1 = r * Math.cos(t)
                const y1 = r * Math.sin(t)
                t += dt
                if (cycle < numCycles - 1) {
                    storedCoords.push([upperStartX + x1, upperStartY + y1])
                } else if (i < numArcSegments / 2) {
                    // stop halfway through last upper arc
                    storedCoords.push([upperStartX + x1, upperStartY + y1])
                }
            }
            if (cycle < numCycles - 1) {
                // straight down
                for (let i = 0; i < numStraightSegments; i++) {
                    storedCoords.push([braidW + cycle * braidW, upperStartY + i * coordSpan])
                }
                // lower arc
                t = 0
                r = cycle * braidW + braidW
                for (let i = 0; i < numArcSegments; i++) {
                    const x1 = r * Math.cos(t)
                    const y1 = r * Math.sin(t)
                    t += dt
                    storedCoords.push([x1, y1])
                }
            }
        }
        generatePath(linesGroup, storedCoords)
    }

    function generatePath(linesGroup, coords) {
        const lineFn = d3
            .line()
            .x((d) => {
                return d[0]
            })
            .y((d) => {
                return d[1]
            })
        const rugPath = linesGroup
            .append('path')
            .attr('stroke', 'teal')
            .attr('stroke-width', '1')
            .attr('fill', 'none')
            .attr('d', lineFn(coords))

        addBraidlets(linesGroup, rugPath)
    }

    function addBraidlets(svgGroup, rugPath, coords) {
        const segLen = braidW / 2
        const segOffset = segLen / 2 + segLen / 2 / braidW
        // segOffset += 0.25;

        let cycleIndex = 0
        let linearSamples = []
        if (rugPath) {
            const l = rugPath.node().getTotalLength()
            const M = Math.round(linearSamplingRatio * l) // number of braid groups
            // console.log("now M is", M, "segLen", segLen, "segOffset", segOffset, "braidW", braidW)
            linearSamples = Array.from({ length: M }, (_, i) => {
                const { x, y } = rugPath.node().getPointAtLength((i / M) * l)
                let angle = -90
                if (i > 0) {
                    const prev = rugPath.node().getPointAtLength(((i - 1) / M) * l)
                    angle = Math.round((Math.atan2(y - prev.y, x - prev.x) * 180) / Math.PI) // angle for tangent
                    // increment cycle on upper arc turn, but not first one
                    if (cycleIndex !== 0 && prev.x < 0 && x > 0) {
                        cycleIndex += 1
                    }
                }
                if (cycleIndex === 0 && angle !== -90) {
                    cycleIndex += 1
                }

                return [x, y, angle, Array(6).fill(cycleIndex)]
            })
        } else {
            linearSamples = coords.map((c, i) => {
                let angle = -90
                if (i > 0) {
                    const prev = coords[i - 1]
                    angle = (Math.atan2(c[1] - prev[1], c[0] - prev[0]) * 180) / Math.PI
                }
                return [c[0], c[1], angle, Array(6).fill(i)]
            })
        }

        const braidGroups = svgGroup
            .selectAll('.braid-group')
            .data(linearSamples)
            .enter()
            .append('g')
            .attr('transform', (d) => {
                // Shifting to center of braid
                const shiftX = segLen * 3
                const shiftY = -1
                const centerX = d[0] - shiftX
                const centerY = d[1] - shiftY
                return 'translate(' + centerX + ',' + centerY + ')rotate(' + d[2] + ' ' + shiftX + ' ' + shiftY + ')'
            })
        braidGroups
            .selectAll('.braid-section')
            .data((d) => {
                return d[3]
            })
            .enter()
            .append('line')
            .attr('class', (d, i) => {
                return 'braidlet row' + d + '-strand' + (i % 3)
            })
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', segLen)
            .attr('y2', 0)
            .attr('transform', (d, i) => {
                const angle = i % 2 === 0 ? -43 : 43
                const x = i * segOffset
                const y = angle < 0 ? segOffset : -segLen
                return 'rotate(' + angle + ' ' + x + ',' + y + ') translate(' + x + ', ' + y + ')'
            })
            .attr('stroke', (d, i) => {
                return colors[d][i % 3]
            })
            .attr('stroke-width', '4')
            .attr('stroke-linecap', 'round')

        // // show origin
        // svgGroup
        //     .append("circle")
        //     .attr("cx", 0)
        //     .attr("cy", 0)
        //     .attr("r", 3)
        //     .attr("fill", "red");

        updateOutput()
        hideLoading()
    }

    function setupMyRug() {
        colors = myRugColors()
        handleColorsUpdate()
    }

    function addColorRow(parent, colorSet, rowIndex) {
        const newDiv = document.createElement('div')
        const newLabel = document.createTextNode('Row ' + (rowIndex + 1))
        newDiv.className = 'row-label'
        newDiv.appendChild(newLabel)
        parent.appendChild(newDiv)
        colorSet.forEach((cs, i) => {
            const newColor = document.createElement('div')
            // const newColor = document.createElement('input')
            newColor.className = 'color-cube'
            newColor.style = 'background: ' + cs + ';'
            // newColor.type = "color";
            // newColor.value = cs;
            newColor.id = 'row' + rowIndex + '-strand' + i
            // newColor.addEventListener("input", colorChanged, false);
            parent.appendChild(newColor)
        })
    }

    function updateOutput() {
        const uniqColors = _.uniq(_.flatten(colors))
        // console.log('uniqColors', uniqColors)
        d3.selectAll('#output svg').attr('height', 35 * uniqColors.length + 200)
        colorCounts = {}
        uniqColors.forEach((c) => {
            colorCounts[c] = 0
        })
        // row 1 = 26 units (for one color)
        // row 2 = 62 units
        // row 30 = 294 units

        // Length of center braid is determined by size of rug you want to make.
        // Subtract the width from the length to arrive at figure for center braid.
        // For example, in a 4' X 6' rug, the center braid would be two feet.
        // so 2 feet = 26 units

        // But a braided foot of fabric !== a flat foot of fabric.
        // It's about 18:12 inches, or 19:12, to include joining allowance
        // so for every 12 inches of rug, we need 19 inches of fabric (x3)

        // Assuming strips will be 2.5", let's say you could get 23 strips per yard of a 58" wide bolt
        // or, to be more conservative, 22 strips

        // 1 braided foot = 13 units = 19 inches = 0.53 yard

        d3.selectAll('.braidlet').each((d, i) => {
            const c = colors[d][i % 3]
            colorCounts[c] += 1
        })
        // console.log("colorCounts in updateOutput", colorCounts);
        const yards = {}
        let totalYards = 0
        uniqColors.forEach((c) => {
            const feet = colorCounts[c] / 13
            const y = feet / 1.89 // for braided foot (19")
            // divide by 22 for 58" wide bolt (could maybe get 23)
            yards[c] = (y / 22).toFixed(2) // Math.round(y / 22)
            totalYards += parseFloat(yards[c])
        })
        // 35 was predicted (from w x l, or 5 x 7), and it came out to that when saying 18 strips per yard, for 54'' wide bolts
        // console.log("yards", yards, "totalYards", totalYards);

        outputSvg.selectAll('.output').remove()
        // heading
        outputSvg
            .append('text')
            .attr('class', 'output')
            .attr('font-size', '22px')
            .attr('x', 140)
            .attr('y', 0)
            .attr('text-anchor', 'middle')
            .text('Fabric needed')
        outputSvg
            .append('text')
            .attr('class', 'output')
            .attr('font-size', '18px')
            .attr('x', leftMargin)
            .attr('y', topMargin)
            .text('For 58" wide bolts:')

        // color rectangle
        outputSvg
            .selectAll('rect')
            .data(uniqColors)
            .enter()
            .append('rect')
            .attr('class', 'output')
            .attr('x', leftMargin)
            .attr('y', (d, i) => {
                return topMargin * 2 + i * 35
            })
            .attr('width', 20)
            .attr('height', 20)
            .attr('fill', (d) => {
                return d
            })

        // yards per color
        outputSvg
            .selectAll('.label')
            .data(uniqColors)
            .enter()
            .append('text')
            .attr('class', 'output')
            .attr('x', leftMargin + 35)
            .attr('y', (d, i) => {
                return topMargin * 2 + 15 + i * 35
            })
            .text((d) => {
                return yards[d] + ' yards'
            })

        // hex values
        outputSvg
            .selectAll('.label')
            .data(uniqColors)
            .enter()
            .append('text')
            .attr('class', 'output hex-label')
            .attr('x', leftMargin + 175)
            .attr('y', (d, i) => {
                return topMargin * 2 + 15 + i * 35
            })
            .text((d) => {
                return d
            })

        // total yards
        outputSvg
            .append('text')
            .attr('class', 'output')
            .attr('font-size', '18px')
            .attr('x', leftMargin)
            .attr('y', topMargin * 2 + uniqColors.length * 35 + 30)
            .text('Total yards: ' + totalYards.toFixed(2))
    }

    function downloadPattern() {
        createDownload(colors)
    }

    function uploadPattern(e) {
        showLoading()
        processUpload(e.target.files[0], (result) => {
            document.getElementById('select-preset').value = -1
            colors = result
            handleColorsUpdate()
        })
    }

    function showExample() {
        document.getElementById('main-content').style.display = 'none'
        fetch('../html/example.html')
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                document.querySelector('tabs-content').innerHTML = data
            })
    }

    function showFAQ() {
        document.getElementById('main-content').style.display = 'none'
        fetch('../html/faq.html')
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                document.querySelector('tabs-content').innerHTML = data
            })
    }

    function showFeedback() {
        document.getElementById('main-content').style.display = 'none'
        fetch('../html/feedback.html')
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                document.querySelector('tabs-content').innerHTML = data
            })
    }

    function showHowTo() {
        document.getElementById('main-content').style.display = 'none'
        fetch('../html/howto.html')
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                document.querySelector('tabs-content').innerHTML = data
            })
    }

    function showPlanner() {
        document.querySelector('tabs-content').innerHTML = ''
        document.getElementById('main-content').style.display = 'block'
    }

    function showLoading() {
        return new Promise((resolve) => {
            document.getElementById('loading-ring-container').style.display = 'block'
            document.getElementById('svg-container').style.opacity = 0
            document.getElementById('color-rows').style.opacity = 0
            document.getElementById('output').style.opacity = 0
            setTimeout(() => {
                resolve()
            }, 500)
        })
    }

    function hideLoading() {
        document.getElementById('loading-ring-container').style.display = 'none'
        document.getElementById('svg-container').style.opacity = 1
        document.getElementById('color-rows').style.opacity = 1
        document.getElementById('output').style.opacity = 1
    }

    function rgbToHex(rgb) {
        // Turn "rgb(r, g, b)" into [r,g,b]
        rgb = rgb.substr(4).split(')')[0].split(', ')
        let r = (+rgb[0]).toString(16)
        let g = (+rgb[1]).toString(16)
        let b = (+rgb[2]).toString(16)
        if (r.length === 1) r = '0' + r
        if (g.length === 1) g = '0' + g
        if (b.length === 1) b = '0' + b
        return '#' + r + g + b
    }
    // function hexToRgb(hex) {
    //     const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    //     return result ? 'rgb(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ')' : null
    // }

    function pickrColorChanged(origColor) {
        // pickr.getColor():HSVaColor - Returns the current HSVaColor object.
        // pickr.getSelectedColor():HSVaColor - Returns the currently applied color.
        const hsva = pickr.getColor()
        const newColor = hsva.toHEXA().toString().toLowerCase()

        if (document.getElementById('yes-all-instances').checked) {
            // essentially, this only gets called when
            // one square is selected, so origColor is defined
            const origHex = rgbToHex(origColor)
            // Note: optimize???
            colors = colors.map((r, i) => {
                r = r.map((c, j) => {
                    if (c === origHex) {
                        c = newColor
                        document.getElementById('row' + i + '-strand' + j).style.background = newColor
                    }
                    return c
                })
                return r
            })
            d3.selectAll('.braidlet').attr('stroke', (d, i) => {
                return colors[d][i % 3]
            })
        }
        const boxes = selection.getSelection()
        boxes.forEach((b) => {
            b.style = 'background:' + newColor + ';'
            d3.selectAll('.' + b.id).attr('stroke', newColor)
            let row = b.id.split('row')[1]
            row = row.split('-')[0]
            const strandIndex = b.id.split('strand')[1]
            colors[row][strandIndex] = newColor
        })
        updateOutput()
    }

    function clearSelection(event) {
        document.querySelectorAll('.selected').forEach((x) => x.classList.remove('selected'))
        selection.clearSelection()
    }

    /*****************************************/
    /**          Instantiate Pickr          **/
    /*****************************************/
    const pickr = Pickr.create({
        el: '.color-picker',
        theme: 'classic', // or 'monolith', or 'nano'
        comparison: true,
        autoReposition: false,
        swatches: [
            'rgb(244, 67, 54)',
            'rgb(233, 30, 99)',
            'rgb(156, 39, 176)',
            'rgb(103, 58, 183)',
            'rgb(63, 81, 181)',
            'rgb(33, 150, 243)',
            'rgb(3, 169, 244)',
            'rgb(0, 188, 212)',
            'rgb(0, 150, 136)',
            'rgb(76, 175, 80)',
            'rgb(139, 195, 74)',
            'rgb(205, 220, 57)',
            'rgb(255, 235, 59)',
            'rgb(255, 193, 7)',
            'rgb(255, 255, 255)'
        ],
        components: {
            // Main components
            preview: true,
            opacity: false,
            lockOpacity: true,
            hue: true,
            // Input / output Options
            interaction: {
                hex: false,
                rgba: false,
                hsla: false,
                hsva: false,
                cmyk: false,
                input: true,
                clear: false,
                save: true
            }
        }
    })
    /*******************************************/
    /**          Add Pickr listeners          **/
    /*******************************************/
    const allInstancesOption = document.getElementById('all-instances-option')
    const instanceCube = document.getElementById('instance-cube')
    // not listening to: init, hide, clear, change, cancel
    // https://github.com/Simonwep/pickr
    pickr
        .on('show', (color, instance) => {
            const style = instance.getRoot().app.style
            style.left = 'auto'
            style.top = 0
            style.right = 0
            const selected = selection.getSelection()
            if (selected.length === 1) {
                this.origColor = selected[0].style['background-color']
                instanceCube.style.background = this.origColor
                allInstancesOption.style.opacity = 1
                allInstancesOption.style.display = 'block'
                instance.getRoot().app.appendChild(allInstancesOption)
            } else {
                document.getElementById('yes-all-instances').checked = false
                document.getElementById('only-one-instance').checked = true
                allInstancesOption.style.display = 'none'
            }
        })
        .on('changestop', (source, instance) => {
            applyPickedColor(instance, this.origColor)
            // update origColor
            const selected = selection.getSelection()
            if (selected.length === 1) {
                this.origColor = selected[0].style['background-color']
                instanceCube.style.background = this.origColor
            }
        })
        .on('save', (color, instance) => {
            // have to do this, for hex colors entered in input field
            applyPickedColor(instance, this.origColor)
            // update origColor
            const selected = selection.getSelection()
            if (selected.length === 1) {
                this.origColor = selected[0].style['background-color']
                instanceCube.style.background = this.origColor
            }
        })
        .on('swatchselect', (color, instance) => {
            applyPickedColor(instance, this.origColor)
            // update origColor
            const selected = selection.getSelection()
            if (selected.length === 1) {
                this.origColor = selected[0].style['background-color']
                instanceCube.style.background = this.origColor
            }
        })
    function applyPickedColor(instance, origColor) {
        // pickr.applyColor(silent:Boolean):Pickr -
        // Same as pressing the save button.
        // If silent is true the onSave event won't be called. <--- !
        instance.applyColor(true)
        pickrColorChanged(origColor) // param is only used for "change all"
        if (origColor) {
            instance.removeSwatch(15)
            instance.addSwatch(origColor)
        }
        // console.log("compare", instance._lastColor, origColor);
    }

    /*****************************************/
    /**      Instantiate Selction tool      **/
    /*****************************************/
    // https://github.com/Simonwep/selection
    const selection = Selection.create({
        // Class for the selection-area
        class: 'selection-area',
        // All elements in this container can be selected
        selectables: ['#rows-container > .color-cube'],
        // The container is also the boundary in this case
        boundaries: ['#rows-container']
        // px, how many pixels the point should move before starting the selection (combined distance).
        // Or specifiy the threshold for each axis by passing an object like {x: <number>, y: <number>}.
        // startThreshold: 10,
    })
        .on('start', ({ inst, selected, oe }) => {
            // console.log('start triggered', selected.length)
            // Remove class if the user isn't pressing the control key or ⌘ key
            if (!oe.ctrlKey && !oe.metaKey) {
                // Unselect all elements
                for (const el of selected) {
                    el.classList.remove('selected')
                    inst.removeFromSelection(el)
                }
                // Clear previous selection
                inst.clearSelection()
            }
        })
        .on('move', ({ changed: { removed, added } }) => {
            // console.log('move triggered', removed.length, added.length)
            // Add a custom class to the elements that were selected.
            for (const el of added) {
                el.classList.add('selected')
            }
            // Remove the class from elements that were removed
            // since the last selection
            for (const el of removed) {
                el.classList.remove('selected')
            }
        })
        .on('stop', ({ inst }) => {
            inst.keepSelection()
            pickr.show()
        })

    this.init = init
}

module.exports = {
    Braider: Braider
}
